import { template as template_1194df9eba7c42a292d00d82cb060abe } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const LikesCell = template_1194df9eba7c42a292d00d82cb060abe(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default LikesCell;
