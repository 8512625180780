import { template as template_80b3a4a0af3740fabf215440b756c649 } from "@ember/template-compiler";
const FKText = template_80b3a4a0af3740fabf215440b756c649(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
