import { template as template_6ba744db9bc24a73a3cbfde9046254ff } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const RepliesCell = template_6ba744db9bc24a73a3cbfde9046254ff(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="posts"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="replies"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default RepliesCell;
