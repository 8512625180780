import { template as template_c3fa034212754880b7b40d2a6eed01d5 } from "@ember/template-compiler";
const FKControlMenuContainer = template_c3fa034212754880b7b40d2a6eed01d5(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
